import React, { Fragment } from 'react'
//import Checkbox from '../Checkbox'
import ValidityWarning from '../ValidityWarning'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    FormControlLabel,
    Checkbox,
    Switch,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styled from 'styled-components'
import { withTranslation, Trans } from 'gatsby-plugin-react-i18next'
const H3 = styled.h3`
    margin: 0 0.5rem;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 1rem;
    flex-wrap: wrap;
    margin-bottom: -1rem;
`

class CheckboxGroup extends React.Component {
    constructor(props) {
        super(props)
        const checked = {}
        const secondaryChecked = {}
        if (props.initialChecked) {
            props.initialChecked.forEach((spec) => {
                checked[spec] = true
            })
        }
        if (props.secondaryInitialChecked) {
            props.secondaryInitialChecked.forEach((sChecked) => {
                secondaryChecked[sChecked] = true
            })
        }

        this.state = {
            checked,
            secondaryChecked,
            valid: props.valid,
        }
    }

    checkedArr = (checkedObj, name) =>
        Object.keys(checkedObj).filter((key) => {
            return checkedObj[key] === true
        })

    onChange = (evt) => {
        const { name } = evt.target
        const checked = {
            ...this.state.checked,
            [name]: !this.state.checked[name],
        }
        const checkedArr = this.checkedArr(checked, name)

        this.props.updateState(this.props.name, checkedArr)
        this.setState({
            checked,
            valid: checkedArr.length > 0,
        })
    }

    handleParentCheckBox = (evt, children, status) => {
        evt.stopPropagation()
        const { name } = evt.target
        const statusToApply = status === 'all' ? false : true
        const newChecks = {}
        for (let i = 0; i < children.length; i++) {
            if (this.props.lawyerOrNotary !== 'paralegal' || !statusToApply) {
                newChecks[children[i].name] = statusToApply
            } else if (children[i].paralegal) {
                newChecks[children[i].name] = statusToApply
            }
        }
        const checked = {
            ...this.state.checked,
            ...newChecks,
        }

        const checkedArr = this.checkedArr(checked, name)
        this.props.updateState(this.props.name, checkedArr)
        this.setState({
            checked,
            valid: checkedArr.length > 0,
        })
    }

    onSecondaryChange = (evt) => {
        evt.stopPropagation()
        const { name } = evt.target
        const secondaryChecked = {
            ...this.state.secondaryChecked,
            [name]: !this.state.secondaryChecked[name],
        }
        const checkedArr = this.checkedArr(secondaryChecked, name)
        this.props.updateState(this.props.secondaryName, checkedArr)
        this.setState({ secondaryChecked })
    }

    parentCheckStatus = (children) => {
        let numChecked = 0
        let possibleChecked = 0
        for (let i = 0; i < children.length; i++) {
            if (this.state.checked[children[i].name]) {
                numChecked++
            }
            if (!children[i].disabled) {
                if (this.props.lawyerOrNotary === 'paralegal') {
                    if (children[i].paralegal) {
                        possibleChecked++
                    }
                } else {
                    possibleChecked++
                }
            }
        }
        if (numChecked === 0) {
            return 'none'
        }
        return numChecked === possibleChecked ? 'all' : 'some'
    }

    renderCheckBoxes = (checkboxes) => {
        return checkboxes.map((checkbox) => {
            if (checkbox.disabled) {
                return null
            }

            if (checkbox.children) {
                if (this.props.lawyerOrNotary === 'paralegal') {
                    let paralegalAvailable = false
                    checkbox.children?.forEach((c) => {
                        if (c.paralegal) {
                            paralegalAvailable = true
                        }
                    })
                    if (!paralegalAvailable) {
                        return null
                    }
                }
                const checkStatus = this.parentCheckStatus(checkbox.children)
                return (
                    <Accordion
                        key={checkbox.name}
                        style={{ maxWidth: '100%', width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-label="Expand">
                            <FormControlLabel
                                style={{ marginRight: 0 }}
                                onClick={(evt) =>
                                    this.handleParentCheckBox(
                                        evt,
                                        checkbox.children,
                                        checkStatus
                                    )
                                }
                                control={
                                    <Checkbox
                                        indeterminate={
                                            checkStatus === 'some'
                                                ? true
                                                : false
                                        }
                                        checked={
                                            checkStatus !== 'none'
                                                ? true
                                                : false
                                        }
                                        name={checkbox.name}
                                    />
                                }
                                label={
                                    <H3 style={{ width: '100%' }}>
                                        <Trans>{checkbox.label}</Trans>
                                    </H3>
                                }
                            />
                            {checkbox.certRequired &&
                            (checkStatus === 'some' ||
                                checkStatus !== 'none') ? (
                                <div
                                    onClick={
                                        (evt) =>
                                            evt.stopPropagation() /* This stops the accordion to toggle */
                                    }>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name={checkbox.name}
                                                checked={
                                                    this.state.secondaryChecked[
                                                        checkbox.name
                                                    ]
                                                        ? true
                                                        : false
                                                }
                                            />
                                        }
                                        onChange={this.onSecondaryChange}
                                        label={
                                            <Trans i18nKey="legalAidCert">
                                                Je suis sur le panel d’aide
                                                juridique
                                            </Trans>
                                        }
                                    />
                                </div>
                            ) : null}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                {this.renderCheckBoxes(checkbox.children)}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )
            } else {
                if (
                    this.props.lawyerOrNotary === 'paralegal' &&
                    !checkbox.paralegal
                ) {
                    return null
                }
                if (checkbox.topLevel) {
                    return (
                        <AccordionSummary key={checkbox.name}>
                            <FormControlLabel
                                control={<Checkbox />}
                                label={
                                    <H3 style={{ width: '100%' }}>
                                        <Trans>{checkbox.label}</Trans>
                                    </H3>
                                }
                                name={checkbox.name}
                                onChange={this.onChange}
                                checked={
                                    this.state.checked[checkbox.name] || false
                                }
                            />
                            {checkbox.certRequired ? (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name={checkbox.name}
                                            checked={
                                                this.state.secondaryChecked[
                                                    checkbox.name
                                                ]
                                                    ? true
                                                    : false
                                            }
                                        />
                                    }
                                    onChange={this.onSecondaryChange}
                                    label={
                                        <Trans i18nKey="legalAidCert">
                                            J'ai un certificat d'aide juridique
                                        </Trans>
                                    }
                                />
                            ) : null}
                        </AccordionSummary>
                    )
                }
                return (
                    <Grid item xs={12} sm={4} key={checkbox.name}>
                        <FormControlLabel
                            control={<Checkbox />}
                            label={<Trans>{checkbox.label}</Trans>}
                            name={checkbox.name}
                            onChange={this.onChange}
                            checked={this.state.checked[checkbox.name] || false}
                        />
                        {checkbox.certRequired &&
                        this.state.checked[checkbox.name] ? (
                            <FormControlLabel
                                control={
                                    <Switch
                                        name={checkbox.name}
                                        checked={
                                            this.state.secondaryChecked[
                                                checkbox.name
                                            ]
                                                ? true
                                                : false
                                        }
                                    />
                                }
                                onChange={this.onSecondaryChange}
                                label={
                                    <Trans i18nKey="legalAidCert">
                                        J'ai un certificat d'aide juridique
                                    </Trans>
                                }
                            />
                        ) : null}
                    </Grid>
                )
            }
        })
    }

    render() {
        return (
            <div>
                <br />
                {!this.state.valid && (
                    <ValidityWarning
                        validityMessage={<Trans>{this.props.helperText}</Trans>}
                    />
                )}
                <Wrapper>
                    {this.renderCheckBoxes(this.props.checkboxes)}
                </Wrapper>
            </div>
        )
    }
}

export default CheckboxGroup
