const apiUrl =
    typeof process !== 'undefined'
        ? process.env.GATSBY_JURIGO_ENV === 'staging'
            ? 'api-qa.jurigo.ca'
            : process.env.GATSBY_JURIGO_ENV === 'production'
            ? 'api.jurigo.ca'
            : 'localhost:8000/api'
        : 'localhost:8000/api'

export default apiUrl
